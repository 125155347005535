$primary: #fcc30b;
$link: #00689d;

// @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&family=Sarabun&display=swap');
@import url('https://fonts.googleapis.com/css2?family=K2D&display=swap');
@import '../node_modules/bulma/bulma.sass';

* {
  // font-family: 'Prompt', sans-serif !important;
  font-family: 'K2D', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

a:hover {
  color:#00aaff;
}

.sidebar {
  min-height: 100vh;
  height: 100%;
  padding-top: 45px;
  padding-bottom: 10px;
  z-index: 10;
  background-color: $link;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
}

.sidebar button {
  margin: 5px;
  cursor: pointer;
  background: none;
  color: #252c40;
  font-size: 28px;
  outline: 0;
  border: 0;
  transition: 0.2s ease-out;
}

.sidebar button:hover {
  color: $primary !important;
}

.active_sidebar {
  color: #FFFFFF !important;
}

.navbar-bar {
  width: 100%;
  height: 49px !important;
  position: fixed;
  padding-left: 50px !important;
  padding-right: 10px !important;
  background-color: $link;
  z-index: 11;
}

.zoom-1 {
  width: 70px !important;
}

.zoom-2 {
  width: 100px !important;
}

.zoom-3 {
  width: 130px !important;
}

.zoom-4 {
  width: 180px !important;
}

.zoom-5 {
  width: 220px !important;
}

.product {
  width: 130px;
  min-height: 160px;
  background-color: white;
  margin-top: 10px;
  margin-right: 5px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  // box-shadow: 1px 1px 4px 0.2px;
  transition: 0.2s ease-out;
  position: relative;
  cursor: pointer;
}
.product p {
  margin: 0;
  text-align: center;
  width: 100%;
  min-height: 30px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 14px;
  z-index: 2;
}
.logo-brand #search {
  margin-right: 15px;
  opacity: 0.6;
  transition: 0.2s;
}
.logo-brand #search:hover {
  opacity: 1;
}
.logo-brand {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 2;
  margin-left: 5px;
  margin-right: 5px;
}

.product:hover {
  transform: translate(5px, -5px);
  z-index: 2;
  // box-shadow: 2px 3px 8px 0.7px;
}

.overlay {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  background-color: black;
  transition: 0.2s;
}

.product:hover .overlay {
  opacity: 0.3;
}

.download {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  padding-left: 50px;
  z-index: 30;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.download button {
  width: 100%;
  height: 100%;
  background-color: $success;
  color: white;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: 0.2s;
}
.download button:hover {
  background-color: hsl(141, 72%, 36%);
}

.list-category {
  width: 200px;
  margin-bottom: 10px;
}

.list-category b {
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s;
}

.list-category li {
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s;
}

.list-category b:hover, .list-category li:hover {
  font-size: 16px;
  color: $link;
}

.fixed-bottom {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 0;
  text-align: center;
}
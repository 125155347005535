

@keyframes ldio-w47kf3a5sw-1 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-45deg) }
  100% { transform: rotate(0deg) }
}
@keyframes ldio-w47kf3a5sw-2 {
    0% { transform: rotate(180deg) }
   50% { transform: rotate(225deg) }
  100% { transform: rotate(180deg) }
}
.ldio-w47kf3a5sw > div:nth-child(2) {
  transform: translate(-15px,0);
}
.ldio-w47kf3a5sw > div:nth-child(2) div {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 30px;
  border-radius: 60px 60px 0 0;
  background: #ffffff;
  animation: ldio-w47kf3a5sw-1 1s linear infinite;
  transform-origin: 30px 30px
}
.ldio-w47kf3a5sw > div:nth-child(2) div:nth-child(2) {
  animation: ldio-w47kf3a5sw-2 1s linear infinite
}
.ldio-w47kf3a5sw > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}@keyframes ldio-w47kf3a5sw-3 {
    0% { transform: translate(95px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { transform: translate(35px,0); opacity: 1 }
}
.ldio-w47kf3a5sw > div:nth-child(1) {
  display: block;
}
.ldio-w47kf3a5sw > div:nth-child(1) div {
  position: absolute;
  top: 46px;
  left: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ffffff;
  animation: ldio-w47kf3a5sw-3 1s linear infinite
}
.ldio-w47kf3a5sw > div:nth-child(1) div:nth-child(1) { animation-delay: -0.67s }
.ldio-w47kf3a5sw > div:nth-child(1) div:nth-child(2) { animation-delay: -0.33s }
.ldio-w47kf3a5sw > div:nth-child(1) div:nth-child(3) { animation-delay: 0s }
.loadingio-spinner-bean-eater-r0ihjaywl67 {
  width: 94px;
  height: 94px;
  display: inline-block;
  overflow: hidden;
}
.ldio-w47kf3a5sw {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.94);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-w47kf3a5sw div { box-sizing: content-box; }
/* generated by https://loading.io/ */